import styled from "styled-components";

const controlPanel = {
  width: 300,
};

export const Container = styled.main<{
  children: React.ReactNode;
}>`
  width: 100vw;
  height: 100vh;
  position: relative;

  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ControlPanel = styled.div<{
  children: React.ReactNode;
}>`
  left: 0;

  border: 1px solid grey;

  width: calc(${controlPanel.width}px + 40px);
  height: 100vh;

  background-color: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding: 20px;

  overflow: auto;
`;

export const ShowcaseStyles = {
  Container: styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  `,

  OutermostContainer: styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
  `,

  OuterContainer: styled.div`
    display: flex;
    align-items: center;
  `,

  SizeControl: styled.button`
    margin: 20px;
  `,

  Element: styled.div<{ width: number; height: number }>`
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
  `,
};
