export const List = () => (
  <>
    <p>Please pick a project name</p>
    <ul>
      <li>
        <a href="/ide-sim">
          IDE Simulator component
        </a>
      </li>
    </ul>
  </>
);
