import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import * as IdeSim from "components/pyyne-ide-sim";
import * as Playground from "components/Playground";
import * as Project from "components/Playground/Projects";

export function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="ide-sim"
          element={<IdeSim.Playground.Example Playground={Playground} />}
        />

        <Route path="" Component={Project.List} />
        <Route path="*" Component={Project.NotFound} />
      </Routes>
    </Router>
  );
}
