import { useState, useEffect, useRef } from "react";
import { useDragGesture } from "hooks";
import { ShowcaseProps } from "interfaces/props";

import { ShowcaseStyles as Styled } from "./styles";

type SizeParams = {
  min: number;
  max: number;
};

const size = (equation: number, { min, max }: SizeParams) =>
  Math.min(max, Math.max(min, equation));

export function Showcase({
  children,
  onSizeChange,

  ...props
}: ShowcaseProps) {
  const ref = useRef<HTMLElement>(null!);

  const [w, sw] = useState(props.style?.width || 800);
  const [h, sh] = useState(props.style?.height || 600);

  // drag offsets
  const [wdo, swdo] = useState<number>(0);
  const [hdo, shdo] = useState<number>(0);

  useDragGesture(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
      hdo &&
      sh(
        size((e.pageY - window.innerHeight / 2) * 2, {
          min: props?.style?.minHeight || 1,
          max: props?.style?.maxHeight || 1080,
        })
      ),
    () => shdo(0)
  );

  useDragGesture(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
      wdo &&
      sw(
        size(e.clientX - wdo / 3, {
          min: props?.style?.minWidth || 1,
          max: props?.style?.maxWidth || 1920,
        })
      ),
    () => swdo(0)
  );

  useEffect(() => {
    if (onSizeChange) onSizeChange({ width: w, height: h });
  }, [w, h, onSizeChange]);

  return (
    <Styled.Container>
      <Styled.OutermostContainer>
        <Styled.OuterContainer>
          <Styled.Element ref={ref as any} width={w} height={h}>
            {children}
          </Styled.Element>

          <Styled.SizeControl onMouseDown={(e) => swdo(e.clientX)}>
            {">"}
          </Styled.SizeControl>
        </Styled.OuterContainer>

        <Styled.SizeControl onMouseDown={() => shdo(1)}>
          {"v"}
        </Styled.SizeControl>
      </Styled.OutermostContainer>
    </Styled.Container>
  );
}
