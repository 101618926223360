const A = Array;

export const pointer =
  (subject: any) =>
  (name: string, value?: string): any => {
    const slices = name.split(".");

    if (value === undefined) {
      if (!slices.length) return subject;
      if (slices.length === 1) return subject?.[name];
      return pointer(subject?.[slices[0]])(slices.slice(1).join("."));
    } else {
      if (!slices.length) return subject;

      const copy = JSON.parse(JSON.stringify(subject));

      if (slices.length === 1) copy[slices[0]] = value;
      else
        copy[slices[0]] = pointer(subject?.[slices[0]])(
          slices.slice(1).join("."),
          value
        );

      return copy;
    }
  };

export const pick = (s: any, p: string[] | object, f = true) =>
  Object.entries(s)
    .filter(([k]) => f === (p instanceof A ? p : Object.keys(p)).includes(k))
    .reduce((a, [k, v]) => ({ ...a, [k]: v }), {});
